/*###########################################################################
// Throttle
//#########################################################################*/

export const throttle = (func, limit) => {
    let lastFunc
    let lastRan
    return function() {
        const context = this
        const args = arguments
        if (!lastRan) {
            func.apply(context, args)
            lastRan = Date.now()
        } else {
            clearTimeout(lastFunc)
            lastFunc = setTimeout(function() {
                if ((Date.now() - lastRan) >= limit) {
                    func.apply(context, args)
                    lastRan = Date.now()
                }
            }, limit - (Date.now() - lastRan))
        }
    }
}

// Use example:
//
// throttle(function() {
//   this.log('THROTTLE')
// }, 3000)
//
// This will throttle the function to run every 3000ms

//###########################################################################
// Debounce
//###########################################################################

export const debounce = (func, delay) => {
    let inDebounce
    return function() {
        const context = this
        const args = arguments
        clearTimeout(inDebounce)
        inDebounce = setTimeout(() =>
                func.apply(context, args)
            , delay)
    }
}

// Use example:
//
// debounce(function() {
//   this.log('DEBOUNCE')
// }, 3000)
//
// This will throttle the function to run *only once* every 3000ms
